export const preExistingCoverage = {
	destination: { code: 'include-pre-existing-coverage' },
	gms: null,
	tugo: null
}

export const tugoExtraCoverage = {
	'Contact Sports - 18+': 'sport-contact',
	'Adventure Sports - All Ages': 'sport-adventure',
	'Extreme Sports - All Ages': 'sport-extreme',
	'Rental Car Protection': 'rental-car-protection',
	'Trip Cancellation & Trip Interruption': 'trip-cancelation-interruption',
	'Trip Interruption': 'trip-interruption'
}