import ACTIONS from "../actions/actionTypes"

const initState = {
  travelFrom: "China",
  travelTo: "Alberta",
  startDate: null,
  endDate: null,
}

export const travelPlan = (state = initState, action) => {
  if (action.type === ACTIONS.SET_TRAVEL_PLAN)
    return {
      ...state,
      ...action.payload,
    }
  return state
}
