import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { FaTimes } from "react-icons/fa"

import { mq } from "../utils/media-query"
import Links from "./Links"
import SocialLinks from "../utils/social-links"

const liStyle = {
  marginBottom: "2rem",
  opacity: "0",
  animation: "slideRight 0.5s ease-in-out 0.3s forwards",
  "&:nth-of-type(1)": { animationDelay: "0.25s" },
  "&:nth-of-type(2)": { animationDelay: "0.5s" },
  "&:nth-of-type(3)": { animationDelay: "0.75s" },
  "&:nth-of-type(4)": { animationDelay: "1s" },
  "@keyframes slideRight": {
    "0%": {
      transform: "translateX(-200px)",
      opacity: "0",
    },
    "100%": {
      transform: "translateX(0)",
      opacity: "1",
    },
  },
}

const liSocialStyle = {
  opacity: "0",
  animation: "slideUp 0.5s ease-in-out 0.3s forwards",
  "&:nth-of-type(1)": { animationDelay: "0.5s" },
  "&:nth-of-type(2)": { animationDelay: "0.75s" },
  "&:nth-of-type(3)": { animationDelay: "1s" },
  "@keyframes slideUp": {
    "0%": {
      transform: "translateY(200px)",
      opacity: "0",
    },
    "100%": {
      transform: "translateY(0)",
      opacity: "1",
    },
  },
}

const linkStyle = {
  fontSize: "2rem",
  display: "block",
  padding: "0 1rem",
  borderRadius: "var(--border-radius)",
  color: "var(--clr-primary-300)",
  fontWeight: "700",
  letterSpacing: "var(--letter-spacing-md)",
  "&:hover": {
    color: "var(--clr-neutral-200)",
    background: "var(--clr-primary-100)",
  },
  transition: "var(--transition)",
}

const AsideStyled = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: "Noto Sans SC", sans-serif;
  background: var(--clr-neutral-200);
  transition: var(--transition);
  display: grid;
  align-items: center;
  justify-content: center;
  z-index: 999;
  transform: ${props =>
    props.showSidebar ? "translateX(0)" : "translateX(-100%)"};
  visibility: ${props => (props.showSidebar ? "visible" : "hidden")};

  ${mq[0]} {
    transform: translateX(-100%);
  }
`

const Sidebar = ({ showSidebar, closeSidebar }) => {
  return (
    <AsideStyled showSidebar={showSidebar}>
      <button
        css={css`
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          background: transparent;
          border: transparent;
          font-size: 2.5rem;
          color: var(--clr-primary-400);
          cursor: pointer;

          &:focus,
          &:active {
            outline: none;
          }
        `}
        onClick={closeSidebar}
      >
        <FaTimes />
      </button>
      <div
        css={css`
          text-align: center;
        `}
      >
        <Links
          linkStyle={showSidebar && linkStyle}
          liStyle={showSidebar && liStyle}
        />
        <SocialLinks liStyle={showSidebar && liSocialStyle} />
      </div>
    </AsideStyled>
  )
}

export default Sidebar
