import { css } from "@emotion/core"

import icons from "../svgs/whyBuyFromCanadaIcons"

const imgStyle = {
  width: "30%",
  margin: "0 auto 1rem auto",
  textAlign: "center",
}

const captionStyle = {
  textTransform: "capitalize",
  fontWeight: "700",
  color: "var(--clr-primary-300)",
  fontSize: "1rem",
}

const textStyle = {
  fontFamily: "var(--ff-quaternary)",
  margin: "0.5rem 1rem",
  color: "var(--clr-primary-300)",
  fontSize: "1rem",
}

const cardStyle = css`
  background: var(--clr-neutral-100);
  padding: 3rem 1.5rem;
  margin-bottom: 2rem;
  border-radius: var(--border-radius);
  text-align: center;
  transition: var(--transition);
  box-shadow: var(--light-shadow);
`

const hoverEffect = css`
  & div svg path {
    fill: var(--clr-primary-300);
  }
  &:hover {
    background: var(--clr-primary-100active);
    color: var(--clr-neutral-200);
  }
  &:hover div svg path {
    fill: var(--clr-neutral-200);
  }
  &:hover p {
    color: var(--clr-neutral-200);
  }
`

export default [
  {
    svg: icons.directBilling,
    caption: "direct billing",
    text: "No need to pay out of your own pocket",
    imgStyle,
    captionStyle,
    textStyle,
    hoverEffect,
    cardStyle,
  },
  {
    svg: icons.preExisting,
    caption: "pre-existing conditions",
    text: "Ever wondered whether your pre-existing conditions are covered?",
    imgStyle,
    captionStyle,
    textStyle,
    hoverEffect,
    cardStyle,
  },
  {
    svg: icons.inCanada,
    caption: "already in canada?",
    text: "Not eligible to buy from your home country anymore? Worry not",
    imgStyle,
    captionStyle,
    textStyle,
    hoverEffect,
    cardStyle,
  },
]
