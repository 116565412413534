import {
  isAtLeast18Yrs,
  isAtLeast15days,
  isValidDate,
  noLaterThanToday,
} from "./time-related-func"

const dateNoneEligibleMessageDefault =
  "Please enter a valid date in YYYY-MM-DD format."

export default {
  isValidDate: [
    {
      validateFunc: isValidDate,
      noneEligibleMessage: dateNoneEligibleMessageDefault,
    },
    {
      validateFunc: noLaterThanToday,
      noneEligibleMessage: "Date of birth cannot be later than current date.",
    },
  ],
  policyHolder: [
    {
      validateFunc: isAtLeast18Yrs,
      noneEligibleMessage: "Policy holder must be at least 18 years old.",
    },
  ],
  spouse: [],
  dependents: [],
  all: [
    {
      validateFunc: isAtLeast15days,
      noneEligibleMessage: "People younger than 15 days are not eligible.",
    },
  ],
}
