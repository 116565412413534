import React from "react"
import styled from "@emotion/styled"
import { navigate } from "gatsby"
import { useDispatch } from "react-redux"

import { InfoCard, PrimaryButton, SecondaryButton } from "."
import { destinWording } from "../utils/insurerPurchaseFlows"
import { mq } from "../utils/media-query"
import { setEligibility } from "../redux/actions"

const DestinPurchaseFlow = () => {
  const destinItems = Object.keys(destinWording)
  const dispatch = useDispatch()
  const confirmEligibility = () => {
    dispatch(setEligibility(true))
    navigate("/get-quote-purchase/review")
  }
  return (
    <Wrapper>
      <InnerWrapper>
        {destinItems.map((item, index) => (
          <InfoCard
            color={destinWording[item].color}
            key={item}
            data={destinWording[item].wording}
            label={item}
            className={index === destinItems.length - 1 ? "span-2" : ""}
            fontSize={destinWording[item].fontSize}
          />
        ))}
      </InnerWrapper>
      <div className="btnWrapper">
        <PrimaryButton
          textSize="0.875rem"
          textTransform="capitalize"
          maxWidth="none"
          margin="0 4rem 0 0"
          width="135px"
          padding="0.5rem 0.75rem"
          onClick={confirmEligibility}
        >
          i understand & i'm eligible
        </PrimaryButton>
        <SecondaryButton
          buttonColor="var(--clr-neutral-400)"
          textSize="0.875rem"
          margin="none"
          onClick={() => navigate(-1)}
        >
          Go back
        </SecondaryButton>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  .btnWrapper {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
`

const InnerWrapper = styled.div`
  display: block;

  ${mq[0]} {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 4rem;
  }

  .span-2 {
    grid-column: 1 / -1;
  }
`

export default DestinPurchaseFlow
