import React from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import { navigate } from "gatsby"

import useInsurersLogos from "../hooks/use-insurers-logos"
import allItems from "../utils/comparingItems"
import { PrimaryButton, SecondaryButton } from "../components"
import { setSelectedPlan } from "../redux/actions"

const PlanComparison = ({ onGoBack }) => {
  const dispatch = useDispatch()
  const comparingInsurers = useSelector(state => state.compareInsurer)
  const quotes = useSelector(state => state.quotes)
  const logos = useInsurersLogos()
  const gridRows = `repeat(${allItems.names.length + 2}, 100px)`
  const gridColumns = `150px ${Object.keys(comparingInsurers).reduce(
    (acc, i) => {
      if (comparingInsurers[i]) acc += "250px "
      return acc
    },
    ""
  )}`

  const selectPlan = plan => {
    dispatch(setSelectedPlan(plan))
    navigate("/get-quote-purchase")
  }

  return (
    <Wrapper gridColumns={gridColumns}>
      <LabelWrapper gridRows={gridRows}>
        <div
          css={css`
            grid-row: 1 / span 2;
          `}
        ></div>
        {allItems.names.map(i => (
          <Label key={i}>{i}</Label>
        ))}
      </LabelWrapper>
      {Object.keys(comparingInsurers).map(insurer => {
        if (comparingInsurers[insurer])
          return (
            <ContentWrapper key={insurer} gridRows={gridRows}>
              <div
                css={css`
                  grid-row: 1 / span 2;
                  text-align: center;
                  display: grid;
                  grid-template-rows: 33% 33% 33%;
                  align-items: center;
                  position: relative;
                `}
              >
                <div
                  css={css`
                    width: 100%;
                    height: 100%;
                    display: grid;
                    align-items: center;
                  `}
                >
                  <Img
                    fluid={logos[insurer].fluid}
                    css={css`
                      vertical-align: middle;
                      width: 100%;
                    `}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                </div>
                <p
                  css={css`
                    font-family: var(--ff-primary);
                    font-size: 2.5rem;
                    letter-spacing: var(--letter-spacing-md);
                    color: var(--clr-primary-300);
                    margin: auto 0;
                  `}
                >
                  {`$${Number.parseFloat(quotes[insurer].price.premium).toFixed(
                    2
                  )}`}
                </p>
                <div>
                  <PrimaryButton
                    textSize="1rem"
                    maxWidth="none"
                    padding="0.5rem 2rem"
                    margin="0 auto"
                    onClick={() => selectPlan(comparingInsurers[insurer])}
                  >
                    buy
                  </PrimaryButton>
                </div>
              </div>
              {allItems.names.map(i => (
                <Content key={i}>{allItems[insurer][i]}</Content>
              ))}
            </ContentWrapper>
          )
      })}
      <div
        css={css`
          grid-column: 1/-1;
          margin-top: 4rem;
        `}
      >
        <SecondaryButton
          buttonColor="var(--clr-neutral-400)"
          textSize="0.875rem"
          margin="none"
          onClick={onGoBack}
        >
          Go back
        </SecondaryButton>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  width: 90%;
  margin: 0 auto;
  grid-template-columns: ${props => props.gridColumns};
  column-gap: 1.25rem;
  justify-content: center;
  justify-items: center;
`

const CellWrapper = styled.div`
  display: grid;
  row-gap: 0.75rem;
  width: 150px;
  grid-template-rows: ${props => props.gridRows};
`

const LabelWrapper = styled(CellWrapper)``

const ContentWrapper = styled(CellWrapper)`
  justify-content: center;
  justify-items: center;
  width: 250px;
`

const Label = styled.div`
  text-transform: capitalize;
  font-weight: bold;
  font-size: 1.1rem;
`

const Content = styled.div`
  width: 100%;
  background-color: var(--clr-neutral-200);
  padding: 0.75rem;
  font-size: 0.75rem;
  font-family: var(--ff-quaternary);
`

export default PlanComparison
