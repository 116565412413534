import { combineReducers } from "redux"

import { currentPath } from "./pathReducer"
import { purposeOfTravel } from "./purposeReducer"
import { travelPlan } from "./travelPlanReducer"
import { travellers } from "./travellersReducer"
import { canProceedFromUI } from "./proceedFromUIReducer"
import {
  coverage,
  deductible,
  unavailableCoverages,
  unavailableDeductibles,
  allCoveragesDeductibles,
} from "./coverageDeductibleReducer"
import {
  apiCallStatus,
  apiHealth,
  tokenExpiryTime,
  countries,
  conditions,
  quotes,
  allQuotesLoaded,
  purchaseResult,
  searchPolicyResult,
} from "./apiReducers"
import { compareInsurer } from "./compareInsurerReducer"
import { selectedPlan } from "./selectedPlanReducers"
import { purchaseConfirmation } from "./purchaseFlowReducer"

export default combineReducers({
  currentPath,
  purposeOfTravel,
  travelPlan,
  travellers,
  canProceedFromUI,
  apiCallStatus,
  apiHealth,
  countries,
  conditions,
  quotes,
  tokenExpiryTime,
  coverage,
  unavailableCoverages,
  deductible,
  unavailableDeductibles,
  allCoveragesDeductibles,
  allQuotesLoaded,
  compareInsurer,
  selectedPlan,
  purchaseConfirmation,
  purchaseResult,
  searchPolicyResult,
})
