import React, { useState, useEffect, useRef } from "react"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import { IoMdInformationCircle } from "react-icons/all"
import {
  CompareCheckbox,
  PrimaryButton,
  SecondaryButton,
  InfoDialogue,
} from "."
import { setComparisonInsurer, setSelectedPlan } from "../redux/actions"

const PlanCard = ({ plan, notAvailable, noPreExisting }) => {
  const dispatch = useDispatch()
  const coverage = useSelector(state => state.coverage)
  const deductible = useSelector(state => state.deductible)
  const [showInfo, setShowInfo] = useState(
    plan.coverageItems.reduce((acc, item) => {
      acc[item] = false
      return acc
    }, {})
  )

  const selectPlan = () => {
    dispatch(setSelectedPlan({ ...plan, coverage, deductible }))
    navigate("/get-quote-purchase")
  }

  const onCompareInsurer = e => {
    dispatch(
      setComparisonInsurer({
        [plan.insurer]: e.target.checked
          ? { ...plan, coverage, deductible }
          : e.target.checked,
      })
    )
  }

  return (
    <CardContainer notAvailable={notAvailable}>
      <div
        css={css`
          width: 90%;
          margin: 0rem auto;
          padding: 1rem 0 1.5rem;
          display: grid;
          grid-template-rows: 1fr 65% 35%;
          align-items: center;
          @media (min-width: 650px) {
            grid-template-rows: 1fr 60% 40%;
            align-items: flex-start;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            height: 30px;
          `}
        >
          <div
            css={css`
              width: 5rem;
              text-align: center;
            `}
          >
            <Img
              fluid={plan.logo}
              css={css`
                vertical-align: middle;
                width: 100%;
                height: 100%;
              `}
              imgStyle={{
                objectFit: "contain",
                marginLeft: plan.insurer === "tugo" && "-0.5rem",
              }}
            />
          </div>
          <CompareCheckbox disabled={!plan.premium}>
            <input
              type="checkbox"
              name={`compare-${plan.insurer}`}
              id={`compare-${plan.insurer}`}
              onClick={onCompareInsurer}
            />
            <label
              htmlFor={`compare-${plan.insurer}`}
              css={css`
                text-transform: uppercase;
                font-family: var(--ff-tertiary);
                color: ${plan.premium
                  ? "var(--clr-primary-300)"
                  : "var(--clr-neutral-300)"};
                font-size: 0.875rem;
                line-height: 1.5rem;
                display: inline-block;
                pointer-events: ${!plan.premium && "none"};
              `}
            >
              compare
            </label>
          </CompareCheckbox>
        </div>
        <div
          css={css`
            display: grid;
            justify-items: center;
            align-items: center;
            align-self: center;

            @media (min-width: 650px) {
              grid-template-rows: 1fr;
              grid-template-columns: 49.5% 1% 49.5%;
              justify-items: center;
              align-items: center;
              align-self: center;
              min-height: 82px;
            }
          `}
        >
          <p
            css={css`
              font-family: ${plan.premium
                ? "var(--ff-primary)"
                : "var(--ff-tertiary)"};
              font-size: ${plan.premium ? "2.75rem" : "1rem"};
              letter-spacing: var(--letter-spacing-md);
              color: var(--clr-primary-300);
              padding: 1rem;
              margin: 1rem 0;
              border-bottom: 2px solid var(--clr-neutral-200);

              @media (min-width: 650px) {
                padding: 0;
                margin: ${plan.premium ? "0" : "0 auto"};
                border-bottom: none;
                grid-column: ${plan.premium ? "1" : "1/-1"};
              }
            `}
          >
            {plan.premium
              ? `$${Number.parseFloat(plan.premium).toFixed(2)}`
              : noPreExisting
              ? `All ${plan.insurer}'s plans include pre-existing conditions coverage.`
              : `${plan.insurer} is not available at this coverage/deductible combination, please try other amounts.`}
          </p>
          {plan.premium && (
            <div
              css={css`
                display: none;
                @media (min-width: 650px) {
                  display: block;
                  width: 2px;
                  height: 100%;
                  background: var(--clr-neutral-200);
                }
              `}
            ></div>
          )}
          {plan.premium && (
            <div>
              <p
                css={css`
                  text-transform: capitalize;
                  font-size: 0.75rem;
                  margin: 0 0 0.5rem 0;
                  padding: 0;
                  color: var(--clr-primary-300);
                  letter-spacing: var(--letter-spacing-sm);
                `}
              >
                coverage items
              </p>
              {plan.coverageItems.map((item, idx) => (
                <div
                  css={css`
                    font-family: var(--ff-quaternary);
                    margin: ${idx !== plan.coverageItems.length - 1
                      ? "0 0 0.5rem 0"
                      : "0"};
                    padding: 0;
                    font-size: 0.875rem;
                  `}
                  key={item}
                >
                  <div
                    css={css`
                      display: inline-block;
                      position: relative;
                    `}
                  >
                    {item}
                    <IoMdInformationCircle
                      css={css`
                        margin-bottom: 0.25rem;
                        cursor: pointer;
                      `}
                      onMouseOver={() =>
                        setShowInfo(
                          Object.assign({}, showInfo, {
                            [item]: !showInfo[item],
                          })
                        )
                      }
                      onMouseOut={() =>
                        setShowInfo(
                          plan.coverageItems.reduce((acc, item) => {
                            acc[item] = false
                            return acc
                          }, {})
                        )
                      }
                    />
                    {showInfo[item] && (
                      <div>
                        <InfoDialogue
                          insurer={plan.insurer}
                          content={item.toLowerCase()}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          css={css`
            position: relative;
          `}
        >
          <div
            css={css`
              display: none;
              @media (min-width: 650px) {
                display: block;
                width: 100%;
                height: 2px;
                background: var(--clr-neutral-200);
                position: absolute;
                top: 0;
                left: 0;
              }
            `}
          ></div>
          <div
            css={css`
              padding: 1rem 0 1.5rem 0;
              margin: 0 auto;

              @media (min-width: 650px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            `}
          >
            <div
              css={css`
                margin: 0 auto 1rem;
                justify-content: center;
                display: flex;
                @media (min-width: 650px) {
                  margin: 0;
                }
              `}
            >
              <PrimaryButton
                textSize="0.75rem"
                maxWidth="none"
                padding="0.5rem 1.5rem"
                margin="0"
                disabled={!plan.premium}
                onClick={selectPlan}
              >
                buy
              </PrimaryButton>
              <SecondaryButton
                buttonColor="var(--clr-primary-100)"
                padding="0.5rem 0.5rem"
                borderLine="1px"
                margin="0 1rem"
                textSize="0.75rem"
                disabled={!plan.premium}
                onClick={() =>
                  typeof window !== undefined &&
                  window.open(`/${plan.insurer}-policy.pdf`, "_blank")
                }
              >
                policy wording
              </SecondaryButton>
              {/* adding tugo's extra coverage options */}
              {/* {
                plan.insurer === "tugo" && 
              } */}
            </div>
            <div
              css={css`
                text-align: center;
              `}
            >
              <SpanStyled>
                Trip length: <SpanStyled>{plan.triplength} days</SpanStyled>
              </SpanStyled>
            </div>
          </div>
        </div>
      </div>
    </CardContainer>
  )
}

const CardContainer = styled.div`
  width: 100%;
  max-width: 800px;
  border-radius: var(--border-radius);
  box-shadow: var(--dark-shadow-sb);
  margin: 0 auto 1rem;
  background-color: ${props => props.notAvailable && "var(--clr-neutral-200)"};
`
const SpanStyled = styled.span`
  font-family: var(--ff-tertiary);
  font-size: 0.75rem;
  letter-spacing: var(--letter-spacing-sm);
  color: var(--clr-primary-300);

  span {
    font-weight: bold;
  }
`
export default PlanCard
