import React, { useState, useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import LabelStyled from "./LabelStyled"
import { setIfCanProceed } from "../redux/actions"

const InputWithLabelSessionStorage = ({
  sessionStorageKey,
  label,
  validateVal,
  errorMessage,
  required,
  id,
  inputWidth,
  inputBorderThickness,
  labelFontSize,
  labelFontWeight,
  wordFontSize,
  inputFontWeight,
  inputMinWidth,
  isTextArea,
  inputTextTransform,
  labelFontFamily,
  inputFontFamily,
  focusedInputBorder,
  focusedInputColor,
  wrapperMaxWidth,
  placeHoderLS,
  wrapperMargin,
  ...props
}) => {
  const dispatch = useDispatch()
  const inputFromSessionStorage =
    typeof window !== "undefined" &&
    window.sessionStorage.getItem(sessionStorageKey)

  const [inputVal, setInputVal] = useState(inputFromSessionStorage)
  const [invalid, setInvalid] = useState(false)
  const [notEligibleMessage, setNotEligibleMessage] = useState("")
  const compRef = useRef(null)

  // when the component unmounts, set canProceedFromUI to true
  useEffect(() => {
    compRef.current = props
  }, [props])

  useEffect(() => {
    return () => dispatch(setIfCanProceed(true))
  }, [compRef, dispatch])

  const onInputChange = e => {
    setInputVal(e.target.value)
  }

  const onInputFocus = () => {
    setInvalid(false)
    dispatch(setIfCanProceed(false))
  }

  const onInputBlur = e => {
    if (required && !e.target.value) {
      setInvalid(true)
      setNotEligibleMessage("Field can't be empty.")
      return
    }

    if (validateVal) {
      if (validateVal["isValidDate"])
        for (let validation of validateVal["isValidDate"]) {
          if (
            validation.validateFunc &&
            !validation.validateFunc(e.target.value)
          ) {
            setInvalid(true)
            setNotEligibleMessage(validation.noneEligibleMessage)
            return
          }
        }

      if (validateVal[sessionStorageKey])
        for (let validation of validateVal[sessionStorageKey]) {
          if (
            validation.validateFunc &&
            !validation.validateFunc(e.target.value)
          ) {
            setInvalid(true)
            setNotEligibleMessage(validation.noneEligibleMessage)
            return
          }
        }

      if (validateVal["all"])
        for (let validation of validateVal["all"]) {
          if (
            validation.validateFunc &&
            !validation.validateFunc(e.target.value)
          ) {
            setInvalid(true)
            setNotEligibleMessage(validation.noneEligibleMessage)
            return
          }
        }
    }

    setInvalid(false)
    dispatch(setIfCanProceed(true))
    sessionStorage.setItem(sessionStorageKey, e.target.value)
  }

  return (
    <Wrapper wrapperMargin={wrapperMargin} wrapperMaxWidth={wrapperMaxWidth}>
      <LabelStyled
        htmlFor={label}
        fontSize={labelFontSize}
        fontWeight={labelFontWeight}
        fontFamily={labelFontFamily}
      >
        {label}
        {required && "*"}
      </LabelStyled>
      <div id={label}>
        {isTextArea ? (
          <StyledTextArea
            cols="1"
            rows="1"
            onBlur={onInputBlur}
            onChange={onInputChange}
            onFocus={onInputFocus}
            invalid={invalid}
            width={inputWidth}
            inputBorderThickness={inputBorderThickness}
            fontSize={wordFontSize}
            fontWeight={inputFontWeight}
            textTransform={inputTextTransform}
            minWidth={inputMinWidth}
          />
        ) : (
          <StyledInput
            {...props}
            value={inputVal || ""}
            onBlur={onInputBlur}
            onChange={onInputChange}
            onFocus={onInputFocus}
            invalid={invalid}
            width={inputWidth}
            inputBorderThickness={inputBorderThickness}
            fontSize={wordFontSize}
            fontWeight={inputFontWeight}
            minWidth={inputMinWidth}
            textTransform={inputTextTransform}
            fontFamily={inputFontFamily}
            focusedInputBorder={focusedInputBorder}
            focusedInputColor={focusedInputColor}
            wrapperMaxWidth={wrapperMaxWidth}
            placeHoderLS={placeHoderLS}
          />
        )}

        <p
          css={css`
            font-size: 0.75rem;
            color: var(--clr-primary-400);
            padding: 0;
            margin: 0;
            text-transform: none;
            visibility: ${invalid ? "visible" : "hidden"};
          `}
        >
          {errorMessage || notEligibleMessage}
        </p>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: ${props => props.wrapperMaxWidth || "20rem"};
  width: 100%;
  margin: ${props => props.wrapperMargin || "0 auto"};
`

const StyledTextArea = styled.textarea`
  display: block;
  width: ${props => props.width || "100%"};
  padding: 1rem;
  border: ${props => props.inputBorderThickness || "2px"} solid
    ${props =>
      props.invalid ? "var(--clr-primary-400)" : "var(--clr-primary-300)"};
  border-radius: var(--border-radius-md);
  text-transform: ${props => props.textTransform || "capitalize"};
  letter-spacing: var(--letter-spacing-sm);
  font-family: var(--ff-tertiary);
  font-size: ${props => props.fontSize || "1rem"};
  font-weight: ${props => props.fontWeight || "normal"};
  color: ${props =>
    props.invalid ? "var(--clr-primary-400)" : "var(--clr-primary-300)"};

  &::placeholder {
    color: ${props =>
      props.invalid ? "var(--clr-primary-400)" : "var(--clr-neutral-300)"};
    text-transform: capitalize;
    letter-spacing: var(--letter-spacing-sm);
  }

  :focus {
    outline: none;
    border: ${props =>
      props.focusedInputBorder || "2px solid var(--clr-primary-100)"};
    color: ${props => props.focusedInputColor || "var(--clr-primary-100)"};
  }

  @media (max-width: 799px) {
    min-width: ${props => props.minWidth};
  }
`

const StyledInput = styled.input`
  display: block;
  width: ${props => props.width || "100%"};
  padding: 1rem;
  border: ${props => props.inputBorderThickness || "2px"} solid
    ${props =>
      props.invalid ? "var(--clr-primary-400)" : "var(--clr-primary-300)"};
  border-radius: ${props => props.borderRadius || "var(--border-radius-md)"};
  text-transform: ${props => props.textTransform || "capitalize"};
  letter-spacing: var(--letter-spacing-sm);
  font-family: ${props => props.fontFamily || "var(--ff-tertiary)"};
  font-size: ${props => props.fontSize || "1rem"};
  font-weight: ${props => props.fontWeight || "normal"};
  color: ${props =>
    props.invalid ? "var(--clr-primary-400)" : "var(--clr-primary-300)"};

  &::placeholder {
    color: ${props =>
      props.invalid ? "var(--clr-primary-400)" : "var(--clr-neutral-300)"};
    text-transform: capitalize;
    letter-spacing: ${props =>
      props.placeHoderLS || "var(--letter-spacing-sm)"};
  }

  :focus {
    outline: none;
    border: ${props =>
      props.focusedInputBorder || "2px solid var(--clr-primary-100)"};
    color: ${props => props.focusedInputColor || "var(--clr-primary-100)"};
  }

  @media (max-width: 799px) {
    min-width: ${props => props.minWidth};
  }
`

export default InputWithLabelSessionStorage
