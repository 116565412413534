import React from "react"
import Img from "gatsby-image"
import { useSelector } from "react-redux"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { navigate } from "gatsby"

import { CardContainerStyled, SecondaryButton } from "."

const PlanDisplayCard = ({
  noChangeBtn,
  borderColor,
  boxShadow,
  plan,
  tripStartDate,
  tripEndDate,
}) => {
  let selectedPlan = useSelector(state => state.selectedPlan)
  let startDate = useSelector(state => state.travelPlan.startDate)
  let endDate = useSelector(state => state.travelPlan.endDate)
  selectedPlan = plan || selectedPlan
  startDate = tripStartDate || startDate
  endDate = tripEndDate || endDate

  return (
    <CardContainerStyled
      maxWidth="700px"
      css={css`
        margin: 0 auto;
      `}
      borderColor={borderColor}
      boxShadow={boxShadow}
    >
      <Wrapper>
        <div className="topPortion">
          <div
            css={css`
              width: 5rem;
              text-align: center;
            `}
          >
            <Img
              fluid={selectedPlan.logo}
              css={css`
                vertical-align: middle;
                width: 100%;
                height: 100%;
              `}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </div>
          {noChangeBtn ? (
            <div></div>
          ) : (
            <div>
              <SecondaryButton
                buttonColor="var(--clr-primary-100)"
                textSize="0.875rem"
                textWeight="700"
                padding="0.5rem 1rem"
                onClick={() => navigate("/get-quote-policies")}
              >
                change
              </SecondaryButton>
            </div>
          )}
        </div>
        <div className="middlePortion">
          <p className="premium">
            $
            {isNaN(parseFloat(selectedPlan.premium))
              ? selectedPlan.premium
              : parseFloat(selectedPlan.premium).toFixed(2)}
          </p>
          <div className="divider"></div>
          <div className="deductible-coverage">
            <p>
              Deductible:{" "}
              <span>
                $
                {selectedPlan.deductible > 999
                  ? [
                      selectedPlan.deductible.toString().slice(0, -3),
                      ",",
                      selectedPlan.deductible.toString().slice(-3),
                    ].join("")
                  : selectedPlan.deductible}
              </span>
            </p>
            <p>
              Coverage:{" "}
              <span>
                $
                {selectedPlan.coverage > 999
                  ? [
                      selectedPlan.coverage.toString().slice(0, -3),
                      ",",
                      selectedPlan.coverage.toString().slice(-3),
                    ].join("")
                  : selectedPlan.coverage}
              </span>
            </p>
            <p className="smallText">
              Policy Start-to-End Date:{" "}
              <span>{startDate && startDate.format("YYYY-MM-DD")}</span> to{" "}
              <span>{endDate && endDate.format("YYYY-MM-DD")}</span>
            </p>
          </div>
        </div>
      </Wrapper>
    </CardContainerStyled>
  )
}

const Wrapper = styled.div`
  .topPortion {
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 1.5rem 3.5rem 0rem 3.5rem;
    justify-content: space-between;
  }

  .middlePortion {
    display: grid;
    justify-items: center;
    align-items: center;
    align-self: center;
    margin-bottom: 2rem;

    @media (min-width: 650px) {
      grid-template-rows: 1fr;
      grid-template-columns: 49.5% 1% 49.5%;
      justify-items: center;
      align-items: center;
      align-self: center;
      min-height: 82px;
      margin-bottom: 1rem;
    }
  }

  .premium {
    font-family: var(--ff-primary);
    font-size: 2.75rem;
    letter-spacing: var(--letter-spacing-md);
    color: var(--clr-primary-300);
    padding: 1rem;
  }

  .divider {
    display: none;
    @media (min-width: 650px) {
      display: block;
      width: 2px;
      height: 90px;
      background: var(--clr-neutral-200);
    }
  }

  .deductible-coverage {
    p {
      margin: 0;
      padding: 0.25rem;
    }

    span {
      font-weight: bold;
    }

    .smallText {
      font-size: 0.6rem;
      text-transform: none;
      margin-top: 1rem;
    }
  }
`

export default PlanDisplayCard
