import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"

import ACTIONS from "../redux/actions/actionTypes"
import {
  setEligibility,
  setLocation,
  setUserFinalConfirmation,
} from "../redux/actions"
import {
  EligibilityCheck,
  InCACheck,
  InCAFollowUp,
  FinalConfirmation,
} from "./GMSPurchase"

const GMSPurchaseFlow = () => {
  const [step, setStep] = useState(1)
  const [preStep, setPrevStep] = useState(0)
  const dispatch = useDispatch()
  const currentPath = useSelector(state => state.currentPath)
  // if component unmounts and url changes, clear all fields
  useEffect(() => {
    return () => {
      if (
        currentPath !== "/get-quote-purchase" &&
        currentPath !== "/get-quote-purchase/review" &&
        currentPath !== "/get-quote-purchase/payment" &&
        currentPath !== ""
      ) {
        dispatch(setEligibility(true))
        dispatch({ type: ACTIONS.CLEAR_ALL_PURCHASECONFIRMATION })
      }
    }
  })
  const flow = {
    1: () => (
      <EligibilityCheck
        onProceed={() => {
          dispatch(setEligibility(true))
          setStep(step + 1)
          setPrevStep(1)
        }}
        onGoBack={() => {
          dispatch({ type: ACTIONS.CLEAR_ELIGIBILITY })
          setPrevStep(0)
          navigate("/get-quote-policies")
        }}
      />
    ),
    2: () => (
      <InCACheck
        onLocInCanada={() => {
          dispatch(setLocation("in CA"))
          setStep(step + 1)
          setPrevStep(2)
        }}
        onLocNotInCanada={() => {
          dispatch(setLocation("not in CA"))
          setStep(4)
          setPrevStep(2)
        }}
        onGoBack={() => {
          dispatch({ type: ACTIONS.CLEAR_PLAN_LOCATION })
          setPrevStep(preStep - 1)
          setStep(step - 1)
        }}
      />
    ),
    3: () => (
      <InCAFollowUp
        onProceed={() => {
          setPrevStep(3)
          setStep(step + 1)
        }}
        onGoBack={() => {
          dispatch({ type: ACTIONS.CLEAR_IN_CA_SITUATION })
          dispatch({ type: ACTIONS.CLEAR_CURRENTPOLICY })
          setPrevStep(preStep - 1)
          setStep(step - 1)
        }}
      />
    ),
    4: () => (
      <FinalConfirmation
        onProceed={() => {
          dispatch(setUserFinalConfirmation(true))
          navigate("/get-quote-purchase/review")
        }}
        onGoBack={() => {
          dispatch(setUserFinalConfirmation(null))
          setStep(preStep)
          setPrevStep(preStep - 1)
        }}
      />
    ),
  }
  return <Wrapper>{flow[step]()}</Wrapper>
}

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`

export default GMSPurchaseFlow
