import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"

import { PrimaryButton, SecondaryButton } from "."
import { tugoWording } from "../utils/insurerPurchaseFlows"
import ACTIONS from "../redux/actions/actionTypes"
import { LabelStyled, InfoCard } from "."
import { setEligibility, setUserFinalConfirmation } from "../redux/actions"

const GMSPurchaseFlow = () => {
  const [step, setStep] = useState(1)
  const [preStep, setPrevStep] = useState(0)
  const dispatch = useDispatch()
  const currentPath = useSelector(state => state.currentPath)
  // if component unmounts and url changes, clear all fields
  useEffect(() => {
    return () => {
      if (
        currentPath !== "/get-quote-purchase" &&
        currentPath !== "/get-quote-purchase/review" &&
        currentPath !== "/get-quote-purchase/payment" &&
        currentPath !== ""
      ) {
        dispatch({ type: ACTIONS.CLEAR_ALL_PURCHASECONFIRMATION })
      }
    }
  }, [currentPath])
  const flow = {
    1: () => (
      <EligibilityCheck
        onProceed={() => {
          dispatch(setEligibility(true))
          setStep(step + 1)
          setPrevStep(1)
        }}
        onGoBack={() => {
          dispatch({ type: ACTIONS.CLEAR_ELIGIBILITY })
          setPrevStep(0)
          navigate("/get-quote-policies")
        }}
      />
    ),
    2: () => (
      <FinalConfirmation
        onProceed={() => {
          dispatch(setUserFinalConfirmation(true))
          navigate("/get-quote-purchase/review")
        }}
        onGoBack={() => {
          dispatch(setUserFinalConfirmation(null))
          setStep(preStep)
          setPrevStep(preStep - 1)
        }}
      />
    ),
  }
  return <Wrapper>{flow[step]()}</Wrapper>
}

const EligibilityCheck = ({ onProceed, onGoBack }) => {
  const tugoItems = tugoWording.eligibility
  return (
    <Wrapper>
      <InnerWrapper>
        <InfoCard
          color={tugoItems.color}
          key={tugoItems.label}
          data={tugoItems.wording}
          label={tugoItems.label}
          fontSize="0.875rem"
        />
      </InnerWrapper>
      <div className="btnWrapper">
        <PrimaryButton
          textSize="0.875rem"
          textTransform="capitalize"
          maxWidth="none"
          margin="0 4rem 0 0"
          onClick={onProceed}
        >
          i'm eligible
        </PrimaryButton>
        <SecondaryButton
          buttonColor="var(--clr-neutral-400)"
          textSize="0.875rem"
          margin="none"
          onClick={onGoBack}
        >
          i'm not eligible
        </SecondaryButton>
      </div>
    </Wrapper>
  )
}

const FinalConfirmation = ({ onProceed, onGoBack }) => {
  return (
    <Wrapper>
      <LabelStyled
        css={css`
          margin-bottom: 1rem;
          text-align: center;
        `}
      >
        DECLARATION
      </LabelStyled>
      <div
        css={css`
          margin-bottom: 3rem;
          line-height: 1.5;
        `}
      >
        {tugoWording.finalConfirmation.split("<br>").map((item, idx) => (
          <p key={idx}>{item}</p>
        ))}
      </div>
      <div className="btnWrapper">
        <PrimaryButton
          textSize="0.875rem"
          textTransform="capitalize"
          maxWidth="none"
          margin="0 4rem 0 0"
          onClick={onProceed}
        >
          i confirm
        </PrimaryButton>
        <SecondaryButton
          buttonColor="var(--clr-neutral-400)"
          textSize="0.875rem"
          margin="none"
          onClick={onGoBack}
        >
          Go back
        </SecondaryButton>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  .btnWrapper {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
`

const InnerWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
`

export default GMSPurchaseFlow
