import React, { useState, useEffect, useRef } from "react"
import { css } from "@emotion/core"
import { GrFormClose } from "react-icons/gr"

import {
  InputWithLabel,
  LabelStyled,
  CardContainerStyled,
  RadioWithLabel,
} from "."
import ACTIONS from "../redux/actions/actionTypes"
import { mq } from "../utils/media-query"
import dateValidations from "../utils/date-validations"

const TravellersCard = ({ role, caption, onCloseCard, id }) => {
  const [focused, setFocused] = useState(false)
  const refContainer = useRef(null)

  useEffect(() => {
    const handleFocusByClick = e => {
      if (refContainer.current && !refContainer.current.contains(e.target)) {
        setFocused(false)
      } else {
        setFocused(true)
      }
    }
    document.addEventListener("click", handleFocusByClick)
    return () => {
      document.removeEventListener("click", handleFocusByClick)
    }
  }, [refContainer])
  return (
    <CardContainerStyled
      ref={refContainer}
      css={css`
        position: relative;
        transition: var(--transition);

        ${focused &&
        "border: 2px solid var(--clr-primary-100);box-shadow: var(--solid-shadow-primary100);"}

        @media (min-width: 800px) {
          width: 100%;
          margin: 0 auto;
        }
        ${mq[1]} {
          width: 90%;
        }
      `}
    >
      {role !== "policyHolder" && (
        <GrFormClose
          css={css`
            position: absolute;
            right: 1rem;
            top: 1rem;
            font-size: 2rem;
            color: var(--clr-primary-300);
            cursor: pointer;
          `}
          onClick={onCloseCard}
        />
      )}
      <div
        css={css`
          padding: 2rem 2rem 4rem 2rem;
          display: grid;
          row-gap: 1.5rem;
          width: 90%;
          margin: 0 auto;
          justify-content: center;

          @media (min-width: 800px) {
            grid-template-columns: 1fr 1fr;
            align-items: flex-start;
            column-gap: 3rem;
            justify-items: center;
            width: 90%;
          }
        `}
      >
        <LabelStyled
          css={css`
            max-width: 20rem;
            padding: 1rem 0;
            font-size: 1.5rem;
            margin-bottom: -1rem;
            justify-self: start;

            @media (min-width: 500px) {
              width: 20rem;
            }
            @media (min-width: 800px) {
              margin: 0 auto;
            }
          `}
        >
          {caption}
        </LabelStyled>
        <div></div>
        <InputWithLabel
          label="first name"
          secondLevelStatePropToChange={role}
          firstLevelStatePropToChange="travellers"
          thirdLevelStatePropToChange="firstName"
          actionType={ACTIONS.SET_TRAVELLERS}
          required
          errorMessage="Please enter valid first name."
          id={id}
        />
        <InputWithLabel
          label="last name"
          secondLevelStatePropToChange={role}
          firstLevelStatePropToChange="travellers"
          thirdLevelStatePropToChange="lastName"
          actionType={ACTIONS.SET_TRAVELLERS}
          required
          errorMessage="Please enter valid last name."
          id={id}
        />
        <InputWithLabel
          label="date of birth"
          secondLevelStatePropToChange={role}
          firstLevelStatePropToChange="travellers"
          thirdLevelStatePropToChange="dob"
          actionType={ACTIONS.SET_TRAVELLERS}
          placeholder="YYYY-MM-DD"
          validateVal={dateValidations}
          required
          id={id}
        />
        <RadioWithLabel
          label="gender"
          name={`${role}-gender`}
          actionType={ACTIONS.SET_TRAVELLERS}
          secondLevelStatePropToChange={role}
          firstLevelStatePropToChange="travellers"
          thirdLevelStatePropToChange="gender"
          options={["female", "male"]}
          required
          id={id}
        />
      </div>
    </CardContainerStyled>
  )
}

export default TravellersCard
