import ACTIONS from "./actionTypes"

export const setTravellers = ({ policyHolder = {}, spouse, dependents }) => ({
  type: ACTIONS.SET_TRAVELLERS,
  payload: {
    policyHolder,
    spouse,
    dependents,
  },
})
