import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { FaQuoteRight } from "react-icons/fa"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"

import reviews from "../utils/customer-reviews"
import { mq } from "../utils/media-query"

const Slider = () => {
  const [index, setIndex] = useState(0)
  useEffect(() => {
    const lastIndex = reviews.length - 1
    if (index < 0) setIndex(lastIndex)
    if (index > lastIndex) setIndex(0)
  }, [index])
  return (
    <Wrapper>
      <div className="section-center">
        {reviews.map((review, idx) => {
          let position = "nextSlide"

          if (idx === index) {
            position = "activeSlide"
          }
          if (
            idx === index - 1 ||
            (index === 0 && idx === reviews.length - 1)
          ) {
            position = "lastSlide"
          }
          return (
            <article className={position} key={idx}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                width="100px"
                height="100px"
                className="img"
              >
                {review.svg}
              </svg>
              <h4>{review.name}</h4>
              <p className="text">{review.wording}</p>
              <FaQuoteRight className="icon" />
            </article>
          )
        })}
        <button className="prev" onClick={() => setIndex(index - 1)}>
          <FiChevronLeft />
        </button>
        <button className="next" onClick={() => setIndex(index + 1)}>
          <FiChevronRight />
        </button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .section-center {
    margin: 4rem auto 0 auto;
    width: 80vw;
    height: 400px;
    max-width: 750px;
    position: relative;
    display: flex;
    overflow: hidden;
    .img {
      border-radius: 50%;
      margin-bottom: 1rem;
      border: 2px solid var(--clr-primary-300);
      fill: var(--clr-primary-300);
      box-shadow: var(--dark-shadow);
    }
    h4 {
      text-transform: capitalize;
      color: var(--clr-primary-500);
      margin-bottom: 0.5rem;
      font-family: var(--ff-tertiery);
      font-weight: 700;
    }
    .text {
      max-width: 85%;
      margin: 0 auto;
      line-height: 2;
      color: var(--clr-primary-300);
      font-family: var(--ff-quaternary);
      text-align: center;
    }

    .icon {
      font-size: 4rem;
      margin-top: 1rem;
      color: var(--clr-primary-500);
    }
    .prev,
    .next {
      position: absolute;
      top: 50px;
      transform: translateY(-50%);
      background: var(--clr-primary-100);
      color: var(--clr-neutral-100);
      width: 2.25rem;
      height: 2.25rem;
      display: grid;
      place-items: center;
      border-color: transparent;
      font-size: 2rem;
      border-radius: var(--border-radius);
      cursor: pointer;
      transition: var(--transition);
    }
    .prev:hover,
    .next:hover {
      background: var(--clr-primary-500);
      outline: none;
    }

    .prev:focus,
    .prev:active,
    .next:focus,
    .next:active {
      outline: none;
    }

    .prev {
      left: 5%;
    }
    .next {
      right: 5%;
    }

    ${mq[0]} {
      height: 350px;
      .icon {
        font-size: 3rem;
      }
      .prev,
      .next {
        width: 2rem;
        height: 2rem;
        font-size: 1.5rem;
      }

      .prev {
        left: 10%;
      }
      .next {
        right: 10%;
      }
    }

    @media (min-width: 892px) {
      height: 320px;
    }

    article {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: var(--transition);
    }
    article.activeSlide {
      opacity: 1;
      transform: translateX(0);
    }
    article.lastSlide {
      transform: translateX(-100%);
    }
    article.nextSlide {
      transform: translateX(100%);
    }
  }
`
export default Slider
