import React from "react"
import { css } from "@emotion/core"

import RegVideo from "./video"
import Title from "./Title"

const WatchShortVid = () => {
  return (
    <section
      css={css`
        padding: 5rem 0;
        text-align: center;
      `}
    >
      <div
        css={css`
          text-align: center;
        `}
      >
        <div>
          <p
            css={css`
              color: var(--clr-primary-500);
            `}
          >
            Why buy travel insurance from canada?
          </p>
          <Title title="watch the short video to learn more" />
        </div>
        <RegVideo />
      </div>
    </section>
  )
}

export default WatchShortVid
