import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

import { CardContainerStyled, InputWithLabelSessionStorage } from "."
import { paymentInfoValidation, paymentInfoItems } from "../utils/payment-info"

const PaymentInfoCard = () => {
  const prevEmail =
    typeof window !== "undefined" && window.sessionStorage.getItem("email")
  return (
    <CardContainerStyled
      maxWidth="700px"
      css={css`
        margin: 0 auto;
        text-transform: none;
      `}
    >
      <Wrapper>
        <div className="innerWrapper">
          {Object.keys(paymentInfoItems).map(item => (
            <InputWithLabelSessionStorage
              key={item}
              label={paymentInfoItems[item].label}
              sessionStorageKey={item}
              required={item !== "wechat"}
              validateVal={
                item === "confirmEmail"
                  ? {
                      ...paymentInfoValidation,
                      confirmEmail: [
                        {
                          validateFunc: confirmEmail =>
                            prevEmail === confirmEmail,
                          noneEligibleMessage: "Both emails have to match.",
                        },
                      ],
                    }
                  : paymentInfoValidation
              }
              labelFontWeight="normal"
              inputFontWeight="bold"
              labelFontSize="0.875rem"
              inputTextTransform="none"
              isTextArea={paymentInfoItems[item].textArea}
              placeholder={item === "phone" ? "000-000-0000" : ""}
            />
          ))}
        </div>
      </Wrapper>
    </CardContainerStyled>
  )
}

const Wrapper = styled.div`
  .innerWrapper {
    padding: 2rem 4rem;
    display: grid;
    row-gap: 1rem;
    column-gap: 4rem;

    @media (min-width: 605px) {
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }
  }
`

export default PaymentInfoCard
