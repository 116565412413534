import moment from "moment"

export const isBeforeDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false

  const aYear = a.year()
  const aMonth = a.month()

  const bYear = b.year()
  const bMonth = b.month()

  const isSameYear = aYear === bYear
  const isSameMonth = aMonth === bMonth

  if (isSameYear && isSameMonth) return a.date() < b.date()
  if (isSameYear) return aMonth < bMonth
  return aYear < bYear
}

export const isInclusivelyAfterDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false
  return !isBeforeDay(a, b)
}

export const isValidDate = val =>
  val.match(/^\d{4}-[01][0-9]-\d{2}$/) && moment(val, "YYYY-MM-DD").isValid()

export const isAtLeast18Yrs = date =>
  moment().diff(moment(date, "YYYY-MM-DD"), "years") >= 18

export const isAtLeast15days = date =>
  moment().diff(moment(date, "YYYY-MM-DD"), "days") >= 15

export const noLaterThanToday = date =>
  moment().diff(moment(date, "YYYY-MM-DD"), "days") >= 0

export const getAge = date => moment().diff(moment(date, "YYYY-MM-DD"), "years")

export const getTravellerAges = travellers => {
  return Array.from(
    new Set(
      Object.keys(travellers).reduce((acc, i) => {
        if (i === "policyHolder" && travellers[i].dob) {
          acc.push(getAge(travellers[i].dob))
        }
        if (i === "spouse" && travellers[i].dob) {
          acc.push(getAge(travellers[i].dob))
        }
        if (i === "dependents" && travellers[i].length > 0) {
          for (const dependent of travellers[i]) {
            if (dependent.dob) acc.push(getAge(dependent.dob))
          }
        }
        return acc
      }, [])
    )
  )
}
