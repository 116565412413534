import { css } from "@emotion/core"

import opportunities from "../../images/opportunities.svg"
import outdoor from "../../images/outdoor.svg"
import study from "../../images/study in Canada.svg"
import visiting from "../../images/visiting families.svg"

import { mq } from "./media-query"

const imgStyle = {
  width: "100%",
  height: "300px",
  margin: "0 auto 1rem auto",
  textAlign: "cover",
  "& img": {
    objectFit: "contain",
    // minHeight: "90%",
    width: "90%",
    height: "90%",

    [mq[1]]: {
      maxWidth: "90%",
    },
  },
}
const extraImgStyle = {
  paddingTop: "1.5rem",
}

const captionStyle = {
  textTransform: "capitalize",
  fontWeight: "700",
  color: "var(--clr-primary-300)",
  fontSize: "1rem",
  letterSpacing: "var(--letter-spacing-sm)",
  width: "50%",
  marginLeft: "auto",
  marginRight: "auto",
}

const cardStyle = css`
  background: none;
  border-radius: var(--border-radius-md);
  text-align: center;
  height: 100%;
  box-shadow: var(--dark-shadow-sb);
  margin: 0 auto;
  width: 90%;
  padding: 1rem;
  align-self: flex-start;
  /* display: grid; */
  ${mq[1]} {
    padding-bottom: 0;
  }
`

const hoverEffect = css`
  &:hover {
    box-shadow: none;
    border: 2px solid var(--clr-primary-300);
  }
`

export default [
  {
    image: {
      src: outdoor,
      alt: "outdoor",
    },
    label: "outdoor adventure",
    caption: "outdoor adventure",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    hoverEffect,
    hoverText:
      "You probably need additional coverage for extreme (contact) sports which might be an exclusion in some plans.",
  },
  {
    image: {
      src: visiting,
      alt: "visiting",
    },
    label: "visiting family",
    caption: "visiting family",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    hoverEffect,
    hoverText:
      "For parents and grandparents, pre-existing condition coverage is strongly recommended. Check out the definitions of pre-existing conditions when you choose the plans.",
  },
  {
    image: {
      src: study,
      alt: "study",
    },
    label: "studying in canada",
    caption: "studying in canada",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    hoverEffect,
    hoverText:
      "Full time students may be eligible for international student insurance, which may offer better coverage and premiums.",
  },
  {
    image: {
      src: opportunities,
      alt: "exploring",
    },
    label: "exploring opportunities",
    caption: "exploring opportunities",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    cardStyle,
    captionStyle,
    hoverEffect,
    hoverText:
      "If you are not sure how long you will stay, buy for the longer period to avoid decline of coverage or increase of premiums for extensions.",
  },
]
