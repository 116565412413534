import React from "react"
import { navigate } from "gatsby"
import { css } from "@emotion/core"

import Title from "./Title"
import { mq } from "../utils/media-query"
import fewClickAwayCards from "../utils/fewClickAwayData"
import Card from "./Card"
import { PrimaryButton } from "./buttons"

const FewClicksAway = () => {
  const onGetMyQuote = () => {
    navigate("/get-quote-details")
  }
  return (
    <section
      css={css`
        padding: 5rem 0;
        background: var(--clr-neutral-200);
        text-align: center;
      `}
    >
      <Title title="you are just a few clicks away from peace of mind" />
      <div
        css={css`
          width: 90vw;
          margin: 0 auto;
          max-width: 1170px;

          ${mq[0]} {
            display: grid;
            grid-template-columns: 1fr 1fr;
            -webkit-column-gap: 2rem;
            -moz-column-gap: 2rem;
            column-gap: 2rem;
          }
          /* 992px */
          ${mq[1]} {
            width: 95vw;
            grid-template-columns: 1fr 1fr 1fr;
          }
        `}
      >
        {fewClickAwayCards.map(cardItem => (
          <Card key={cardItem.label} {...cardItem} />
        ))}
      </div>
      <PrimaryButton
        css={css`
          margin: 1rem auto;
        `}
        onClick={onGetMyQuote}
      >
        get my quote
      </PrimaryButton>
    </section>
  )
}

export default FewClicksAway
