import React from "react"
import { navigate } from "gatsby"
import { css } from "@emotion/core"

import Title from "./Title"
import { PrimaryButton } from "./buttons"

const ReachOutToUs = () => {
  const onContactUs = () => {
    navigate("/contact")
  }
  return (
    <section
      css={css`
        padding: 5rem 0;
        background: var(--clr-neutral-200);
        text-align: center;
      `}
    >
      <Title title="want to reach out to us?" />
      <p
        css={css`
          margin-top: -2rem;
          margin-bottom: 2rem;
          font-family: var(--ff-quaternary);
          font-size: 1rem;
          color: var(--clr-primary-300);
        `}
      >
        We would love to hear from you.
      </p>
      <PrimaryButton
        css={css`
          margin: 0 auto;
        `}
        onClick={onContactUs}
      >
        contact us
      </PrimaryButton>
    </section>
  )
}

export default ReachOutToUs
