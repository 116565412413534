import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { Link, graphql, useStaticQuery, navigate } from "gatsby"
import Image from "gatsby-image"

import Logo from "./Logo"
import { SecondaryButton } from "./buttons"
import { mq } from "../utils/media-query"

const Footer = () => {
  const onGetMyQuote = () => {
    navigate("/get-quote-details")
  }
  const {
    data: { image },
  } = useStaticQuery(graphql`
    query {
      data: file(relativePath: { regex: "/licensed-by/" }) {
        image: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <footer>
      <div
        css={css`
          background: var(--clr-neutral-400);
        `}
      >
        <div
          css={css`
            display: grid;
            margin: 0 auto;
            padding: 3rem;
            justify-items: center;
            width: 90vw;
            max-width: 950px;
            color: var(--clr-neutral-100);
            font-weight: 400;

            @media (min-width: 600px) {
              grid-template-columns: 1fr 1fr;
              row-gap: 2rem;
            }

            ${mq[0]} {
              grid-template-columns: 1fr 1fr 1fr 1fr;
            }
          `}
        >
          <DivStyled
            css={css`
              margin-bottom: 1rem;
              @media (min-width: 600px) {
                grid-column: 1/-1;
              }
              ${mq[0]} {
                grid-column: 1 / span 2;
              }
            `}
          >
            <Link
              to="/"
              css={css`
                width: 120px;
                display: block;
                ${mq[0]}

                & img {
                  height: 100%;
                }
              `}
            >
              <Logo />
            </Link>
            <p>iWare Travel Insurance is licensed by</p>
            <Image
              fluid={image.fluid}
              css={css`
                width: 260px;
                display: block;
                margin: 0 auto;

                & img {
                  width: 100%;
                }
                ${mq[0]} {
                  margin: 0 0;
                }
              `}
            />
          </DivStyled>
          <DivStyled>
            <h4>Resources</h4>
            <Link to="/contact">Contact</Link>
            <Link to="/get-quote-details">Get My Quote</Link>
            <div></div>
          </DivStyled>
          <DivStyled>
            <h4>Legal</h4>
            <Link to="/terms">Terms</Link>
            <Link to="/licenses">Licenses</Link>
          </DivStyled>
        </div>
      </div>
      <div
        css={css`
          background: var(--clr-neutral-200);
        `}
      >
        <div
          css={css`
            height: 4rem;
            width: 90vw;
            max-width: 900px;
            display: flex;
            justify-content: center;
            place-items: center;
            text-align: center;
            margin: 0 auto;

            @media (min-width: 600px) {
              justify-content: space-between;
            }

            p {
              color: var(--clr-primary-300);
              font-family: var(--ff-quaternary);
              margin-bottom: 0;
            }
          `}
        >
          <p>
            &copy; {new Date().getFullYear()} iWareTravel.ca. All rights
            reserved.{" "}
          </p>
          <SecondaryButton
            buttonColor="#446663"
            textSize="0.5rem"
            textWeight="700"
            css={css`
              display: none;
              padding: 0.375rem 1rem;
              margin: 0 0 0 auto;

              @media (min-width: 600px) {
                display: inline-block;
              }
            `}
            onClick={onGetMyQuote}
          >
            Get my quote
          </SecondaryButton>
        </div>
      </div>
    </footer>
  )
}

const DivStyled = styled.div`
  color: var(--clr-neutral-200);
  letter-spacing: var(--letter-spacing-sm);
  ${mq[0]} {
    align-self: center;
    justify-self: center;
  }

  h4 {
    margin-top: 1rem;
    font-family: var(--ff-tertiery);
    font-size: 1.1rem;
    text-align: center;
    color: var(--clr-neutral-200lighter);
    @media (min-width: 600px) {
      text-align: left;
    }
    ${mq[0]} {
      text-align: left;
    }
  }

  a {
    display: block;
    margin: 1.5rem auto;
    font-size: 1rem;
    text-align: center;
    color: var(--clr-neutral-200);

    @media (min-width: 600px) {
      text-align: left;
    }

    ${mq[0]} {
      margin: 1.5rem 0;
      text-align: left;
    }

    &:active,
    &:visited {
      color: var(--clr-neutral-200);
    }

    &:hover {
      color: var(--clr-neutral-100);
    }
  }

  p {
    font-family: var(--ff-quaternary);
    margin-top: 1rem;
    text-align: center;

    ${mq[0]} {
      text-align: left;
      font-size: 0.8rem;
      width: 100%;
    }
  }
`

export default Footer
