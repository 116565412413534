import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getHealth, setToken } from "../redux/actions"

const AppWrapper = ({ children }) => {
  const dispatch = useDispatch()
  const apiHealth = useSelector(state => state.apiHealth)
  const sessionStorage = typeof window !== "undefined" && window.sessionStorage
  const pathname = useSelector(state => state.currentPath)

  useEffect(() => {
    dispatch(getHealth())
  }, [dispatch])
  useEffect(() => {
    dispatch(setToken())
  }, [apiHealth, dispatch])

  useEffect(() => {
    if (!pathname.includes("payment")) sessionStorage.clear()
  }, [pathname])
  return <>{children}</>
}

export default AppWrapper
