import ACTIONS from "../actions/actionTypes"

const initState = {
  policyHolder: {
    firstName: "",
    lastName: "",
    dob: null,
    gender: "",
  },
  spouse: {},
  dependents: [],
}

export const travellers = (state = initState, action) => {
  if (action.type === ACTIONS.SET_TRAVELLERS)
    return {
      policyHolder: Object.assign(
        {},
        state.policyHolder,
        action.payload.policyHolder
      ),
      spouse: action.payload.spouse
        ? Object.keys(action.payload.spouse).length === 0
          ? {}
          : Object.assign({}, state.spouse, action.payload.spouse)
        : state.spouse,
      dependents: action.payload.dependents
        ? Array.isArray(action.payload.dependents)
          ? action.payload.dependents
          : state.dependents.map(dep => {
              if (dep.id === action.payload.dependents.id) {
                return Object.assign({}, dep, action.payload.dependents)
              }
              return dep
            })
        : state.dependents,
    }
  return state
}
