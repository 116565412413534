export default {
	names: [
		'drugs or medications',
		'emergency hospital',
		'Paramedical Services',
		'follow-up visits',
		'Repatriation',
		'dental emergency',
		'Accidental Death & Dismemberment',
		'Waiting period',
		'Pre-existing conditions'
	],
	destination: {
		'drugs or medications': 'up to $1,000 not exceeding 30-day supply',
		'emergency hospital': 'semi-private',
		'Paramedical Services': 'up to $500 per practitioner',
		'follow-up visits': 'Up to CAD3,000',
		'Repatriation': 'Up to $3,000 for a one-way economy transportation',
		'dental emergency': 'Up to CAD3,000 for accidental blow to the face, up to CAD500 for relief of dental pain',
		'Accidental Death & Dismemberment': 'Up to the maximum sum of insured not to exceed of CAD150,000 ',
		'Waiting period': 'If purchased after arrival in Canada, 48 hours applicable to sickness (age 0 to 85). 15 days (age 86 and over)',
		'Pre-existing conditions': 'For 0 to 79, who chooses the Option with pre-existing coverage, 120 days stable period'
	},
	gms: {
		'drugs or medications': 'Up to a 30 day prescription',
		'emergency hospital': 'semi-private',
		'Paramedical Services': 'up to $300',
		'follow-up visits': 'Covered until the medical emergency has been deemed to have ended',
		'Repatriation': 'Up to $5,000 to transport you home',
		'dental emergency': 'Up to $2,000 per person and up to $250 for the relief of dental pain',
		'Accidental Death & Dismemberment': 'N/A',
		'Waiting period': 'If purchased within 30 days of arrival, 2 day waiting period. If purchased more than 30 days of arrival, 7 day waiting period',
		'Pre-existing conditions': 'Covered stable conditions during 180 days prior to the effective date'
	},
	tugo: {
		'drugs or medications': 'Up to a 30-day supply',
		'emergency hospital': 'semi-private',
		'Paramedical Services': 'up to $500 per practitioner',
		'follow-up visits': 'Three follow up visits within 14 days',
		'Repatriation': 'Up to',
		'dental emergency': 'Up to $4,000 for accidental blow to the face, Up to $500 for any other dental emergencies',
		'Accidental Death & Dismemberment': '$25,000',
		'Waiting period': 'if purchased within 60 days of arrival, 48-hour waiting period on sickness. 7-day if purchased 61 days or more after arrival',
		'Pre-existing conditions': 'For 59 years and under, 120 days stable period. for 60 to 69 years, 180 days stable period'
	}
}