import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

import { CardContainerStyled, InputWithLabelSessionStorage } from "."

const PaymentCard = () => {
  return (
    <CardContainerStyled
      maxWidth="600px"
      css={css`
        margin: 0 auto;
        @media (min-width: 650px) {
          width: 600px;
        }
      `}
      borderRadius="var(--border-radius-lg)"
      borderThickness="1px"
      boxShadow="1.5rem 1.5rem 0.75rem var(--clr-neutral-200)"
    >
      <Wrapper>
        <div className="innerWrapper">
          <div className="spanAll">
            <InputWithLabelSessionStorage
              label="cardholder's name"
              sessionStorageKey="cardHolderName"
              required
              labelFontWeight="normal"
              labelFontSize="0.875rem"
              inputTextTransform="none"
              labelFontFamily="var(--ff-quaternary)"
              inputFontFamily="var(--ff-quaternary)"
              focusedInputBorder="1px solid var(--clr-primary-300)"
              focusedInputColor="var(--clr-primary-300)"
              inputWidth="100%"
              wrapperMaxWidth="100%"
              wrapperMargin="0"
              css={css`
                border-radius: var(--border-radius);
                border: none;
                background-color: var(--clr-neutral-200lighter);
              `}
            />
          </div>
          <div className="spanAll">
            <InputWithLabelSessionStorage
              label="card number"
              sessionStorageKey="cardNumber"
              required
              labelFontWeight="normal"
              labelFontSize="0.875rem"
              inputTextTransform="none"
              labelFontFamily="var(--ff-quaternary)"
              inputFontFamily="var(--ff-quaternary)"
              focusedInputBorder="1px solid var(--clr-primary-300)"
              focusedInputColor="var(--clr-primary-300)"
              inputWidth="100%"
              wrapperMaxWidth="100%"
              wrapperMargin="0"
              css={css`
                border-radius: var(--border-radius);
                border: none;
                background-color: var(--clr-neutral-200lighter);
              `}
              placeholder="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0"
              placeHoderLS="var(--letter-spacing-lg)"
            />
          </div>

          <div className="inner">
            <InputWithLabelSessionStorage
              label="month"
              sessionStorageKey="expireMonth"
              required
              labelFontWeight="normal"
              labelFontSize="0.875rem"
              inputTextTransform="none"
              labelFontFamily="var(--ff-quaternary)"
              inputFontFamily="var(--ff-quaternary)"
              focusedInputBorder="1px solid var(--clr-primary-300)"
              focusedInputColor="var(--clr-primary-300)"
              inputWidth="60px"
              css={css`
                border-radius: var(--border-radius);
                border: none;
                background-color: var(--clr-neutral-200lighter);
              `}
              placeholder="0 0"
            />
            <InputWithLabelSessionStorage
              label="year"
              sessionStorageKey="expireYear"
              required
              labelFontWeight="normal"
              labelFontSize="0.875rem"
              inputTextTransform="none"
              labelFontFamily="var(--ff-quaternary)"
              inputFontFamily="var(--ff-quaternary)"
              focusedInputBorder="1px solid var(--clr-primary-300)"
              focusedInputColor="var(--clr-primary-300)"
              inputWidth="60px"
              css={css`
                border-radius: var(--border-radius);
                border: none;
                background-color: var(--clr-neutral-200lighter);
              `}
              placeholder="0 0"
            />
          </div>

          <InputWithLabelSessionStorage
            label="CVC"
            sessionStorageKey="cvc"
            required
            labelFontWeight="normal"
            labelFontSize="0.875rem"
            inputTextTransform="none"
            labelFontFamily="var(--ff-quaternary)"
            inputFontFamily="var(--ff-quaternary)"
            focusedInputBorder="1px solid var(--clr-primary-300)"
            focusedInputColor="var(--clr-primary-300)"
            inputWidth="100%"
            css={css`
              border-radius: var(--border-radius);
              border: none;
              background-color: var(--clr-neutral-200lighter);
            `}
            placeholder="* * *"
            wrapperMargin="0"
          />
        </div>
      </Wrapper>
    </CardContainerStyled>
  )
}

const Wrapper = styled.div`
  .innerWrapper {
    padding: 2rem 5rem;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 4rem;
    justify-items: start;

    @media (max-width: 500px) {
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr;
    }
  }
  .spanAll {
    grid-column: 1/-1;
    width: 100%;
    justify-self: flex-start;
  }

  .inner {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
  }
`

export default PaymentCard
