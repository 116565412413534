export default {
	"medical emergency": {
		destination: 'A sudden, unforeseen sickness or injury occurring during the period of coverage, which requires immediate intervention by a physician or legally licensed dentist and cannot reasonably be delayed.',
		tugo: 'An unforeseen sickness or injury, which requires immediate medical treatment to alleviate existing danger to life or health.',
		gms: 'A sudden or urgent happening that arose during your trip and requires immediate action.'
	},
	"pre-existing conditions": {
		destination: `A sickness, injury or medical condition, whether or not diagnosed by a physician: <br>
    i) for which you exhibited signs or symptoms; or <br>
    ii) for which you required or received any medical consultation; and <br>
		iii) which existed prior to the effective date of your coverage.`,
		gms: `a. medical condition(s) for which you received medical treatment or medical consultation; and/or <br>
		b. undiagnosed medical condition(s) related to symptoms which you received medical treatment or medical consultation.`,
		tugo: `a) A dental or medical condition, illness or injury that has been diagnosed, and for which you have consulted, or received dental or medical treatment before the date you leave for your trip; or, <br>
		b) A dental or medical condition, illness or injury that has not yet been diagnosed, and for which you were experiencing symptoms before the date you leave for your trip; or, <br>
		c) A medically recognized complication or recurrence of a medical or dental condition, illness or injury, whether or not the condition was diagnosed before the date you leave for your trip.`
	}
}