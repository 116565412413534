import React from "react"
import { DestinFlow, GMSFlow, TugoFlow } from "../components"

export default {
  destination: () => <DestinFlow />,
  tugo: () => <TugoFlow />,
  gms: () => <GMSFlow />,
}

export const destinWording = {
  "Coverage will NOT be available if you: ": {
    color: "var(--clr-primary-400)",
    wording: [
      {
        content: [
          "Have been diagnosed with a terminal illness;",
          "Have Acquired Immune Deficiency Syndrome (AIDS) or Human Immunodeficiency Virus (HIV);",
          "Have been prescribed or used home oxygen treatment in the last 12 months;",
          "Have been diagnosed with or treated for congestive heart failure;",
          "Have had a major organ transplant (heart, kidney, liver, lung);",
          "Have received kidney dialysis treatment in the last 12 months;",
        ],
      },
    ],
  },
  "To be eligible, you confirm that you are: ": {
    color: "var(--clr-primary-200)",
    wording: [
      {
        content: [
          "At least 15 days old;",
          "On the effective date, not be insured or eligible for benefits under a Canadian government health insurance plan;",
          "In good health at the time you purchase your policy and on the effective date, and know of no reason why you would attend any medical consultation during the period of coverage.",
        ],
      },
    ],
  },
  "Waiting period:": {
    color: "var(--clr-neutral-400)",
    fontSize: "0.75rem",
    wording: [
      {
        subCap: "Age 0 to 85",
        content: [
          "If the insurance was purchased prior to your arrival date in Canada, the “waiting period” does not apply or,",
          "If this insurance is purchased any time after your arrival in Canada, then in respect of any sickness you will only be entitled to receive benefits for the cost of eligible medical expenses incurred after the first 48 hours from the effective date of the policy.",
        ],
      },
      {
        subCap: "Age 85 and over",
        content: [
          "If the insurance was purchased prior to arrival date in Canada, the “waiting period” does not apply.",
          "If the insurance is purchased after your arrival date in Canada, then in respect of any sickness you will only be entitled to receive benefits for the cost of eligible medical expenses incurred after fifteen (15) days from the effective date of this policy.",
        ],
      },
      {
        subCap: "The waiting period may be waived if:",
        content: [
          "This policy is purchased on or prior to the expiry date of an existing Destination: Canada policy.",
          "If you have insurance with another insurer during the first part of your trip in Canada, and you are purchasing this insurance after your arrival in Canada and there will be no gap in coverage. You must provide satisfactory proof that you have other coverage in force and receive a written approval from the Insurer.",
        ],
      },
    ],
  },
}

export const gmsWording = {
  under55: {
    label: "Under the age of 55 years old, you will NOT be eligible if you:",
    color: "var(--clr-primary-400)",
    wording: [
      {
        content: [
          "1. have any reason to seek medical treatment, excluding the regular care of a chronic condition or medical evaluation required to satisfy travel visa requirements;",
          "2. are currently in Canada, and have ever been denied similar coverage offered by another Canadian insurer; and",
          "3. are currently in Canada, and had more than $5,000 in medical treatment in the last twelve (12) months while in Canada.",
        ],
      },
    ],
  },
  "55orOlder": {
    label:
      "At the age of 55 years old or above, you will NOT be eligible if you:",
    color: "var(--clr-primary-400)",
    wording: [
      {
        content: [
          "1. are eighty (80) years of age or older on the policy effective date;",
          "2. have any reason to seek medical treatment, excluding the regular care of a chronic condition or medical evaluation required to satisfy travel visa requirements;",
          "3. are currently in Canada, and have ever been denied similar coverage offered by another Canadian insurer;",
          "4. are currently in Canada, and had more than $5,000 in medical treatment in the last twelve (12) months while in Canada;",
          "5. are expecting medical treatment for heart disease;",
          "6. are waiting for test(s) for a suspected heart condition;",
          "7. are taking prescription drugs for heart disease while taking insulin to treat diabetes;",
          "8. have an implantable cardioverter defibrillator (ICD); daily living (ADL) if you are seventy (70) years of age or older; and/or",
          "9. fainted or fell more than once without medical diagnosis (syncope);",
          "10. use home oxygen for a medical condition;",
          "11. take oral steroids to treat a lung condition;",
          "12. are being treated for cancer or have Metastic Cancer;",
          "13. have a vascular aneurysm that is surgically untreated;",
          "14. have ever had: <br>a. a valve replacement; <br>b. kidney (renal) dialysis; or <br>c. an organ transplant;",
          "15. were diagnosed; received new medical treatment (e.g. consultation, tests or prescription drugs); or had a change in your medical treatment (e.g. a stop, start or dosage change to a prescription drug, other than a dosage change of Coumadin or Warfarin) for, any of the following conditions in the last twelve (12) months: <br>a. congestive heart failure; <br>b. atrial flutter; <br>c. atrial / ventricular fibrillation; <br>d. peripheral vascular disease; <br>e. terminal illness; <br>f. blood clots; or <br>g. gastrointestinal bleeding;",
          "16. require assistance from another person(s) with activities of daily living (ADL) if you are seventy (70) years of age or older.",
        ],
      },
    ],
  },
  inCAFollowUp: [
    "I am purchasing this plan to replace my current Canadian health insurance plan.",
    "I have been in Canada for LESS than 30 days and am not currently covered by a Canadian health insurance plan.",
    "I have been in Canada for MORE than 30 days and am not currently covered by a Canadian health insurance plan.",
  ],
  finalConfirmation: `I/We ("I") declare the statements made herein are true and complete and shall form part of my application for coverage. I hereby authorize any physician, health care provider, other person, hospital, institution, or insurance company to release to Group Medical Services and/or its authorized agents, representatives, affiliates or other service providers (collectively "GMS") any information covering my medical history, symptoms, treatment, examination, diagnosis and/or services rendered to myself or any of my dependants herein listed. 
   <br>For the purposes of administering any Group Medical Services ("GMS") benefits, products or services (collectively "benefits") and/or determining eligibility for benefits, I authorize GMS to: (a) collect, store and use any personal information which I have provided to GMS or personal information obtained pursuant to clause (b); and/or (b) obtain personal information about me (or any other person listed herein) from, or disclose such personal information to: my Government Health Insurance Plan; the operator of any hospital, clinic, or other health facility; a doctor or other health care provider; any insurance company; or any other service provider or third party as may be reasonably required for the purposes described above. <br>I warrant that neither I, nor any person herein listed, have any additional coverage through any insurer other than the information listed herein. Should I, or any person herein listed, subsequently obtain additional coverage through any insurer, while covered under this contract, I will immediately advise GMS in writing. I hereby authorize GMS to coordinate any eligible expenses with any additional insurer that I, or any person herein listed, may have coverage under. 
   <br>I understand that, whether before or after my application, any misrepresentation, incorrect or concealed information or failure to fully complete all sections of the application may void my coverage. I declare that, if I am signing on behalf of any person(s), I have the authority to sign on behalf of such person(s) listed herein and confirm that each of the above declarations and authorizations are also provided on behalf of such person(s).`,
}

export const tugoWording = {
  eligibility: {
    label: "Eligibility",
    color: "var(--clr-primary-200)",
    wording: [
      {
        subCap: "Are you eligible for coverage?",
        content: [
          "At the time of application, you are eligible for coverage if:",
          "1. You are not travelling against a physician's advice.",
          "2. You have not been diagnosed with a terminal condition..",
          "3. You are not receiving palliative care or palliative care has not been recommended.",
        ],
      },
    ],
  },
  finalConfirmation: `The traveller confirms that they know of no reason for which they may seek medical attention.
  <br>The traveller confirms that they have not seen a physician or other registered medical practitioner since their arrival in Canada
  <br>The traveller confirms that they have not submitted a claim and no circumstance is known for which a claim may be made.  
  <br>The traveller confirms that if this policy is purchased within 60 days after their arrival in Canada, there is no coverage for any sickness arising in, occurring sin or symptomatic during the first 48 hours from the effective date of this policy.
  <br>The traveller understands that if this policy is purchase more than 60 days after their arrival in Canada, there is no coverage for any sickness arising in, occurring in or symptomatic during the first 7 days from the effective date of this policy.`,
}
