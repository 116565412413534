import { provinceCode } from "./provinces"
import { preExistingCoverage } from "./quotesOptions"
import { paymentInfoItems, paymentItems } from "./payment-info"

export const setTravellersPayload = travellers => {
  return Object.keys(travellers)
    .map(role => {
      if (role !== "dependents" && Object.keys(travellers[role]).length > 0)
        return {
          first_name: travellers[role].firstName,
          last_name: travellers[role].lastName,
          birth_date: travellers[role].dob,
          relationship_status: role.toLowerCase(),
          gender: travellers[role].gender,
        }
      if (role === "dependents")
        return travellers[role].map(dep => ({
          first_name: dep.firstName,
          last_name: dep.lastName,
          birth_date: dep.dob,
          relationship_status: "dependant",
          gender: dep.gender,
        }))
      return []
    })
    .flat()
}

export const coverageDeductibleCombo = conditions => {
  return Object.keys(conditions).reduce((acc, key) => {
    if (key !== "travelance") {
      acc[key] = {}
      acc[key]["deductible"] = conditions[key]["deductible"]["options"]
      acc[key]["coverage"] = conditions[key]["limit"]["options"]
    }
    return acc
  }, {})
}

export const getUniqueVals = (insurer, type, all) => {
  let result = all[insurer][type]
  for (let key of Object.keys(all)) {
    if (key === insurer) continue
    result = result.filter(d => !all[key][type].includes(d))
  }
  return result
}

export const getUnavailableCoveragesOrDeductibles = (type, selected, all) => {
  let result = []

  // generate unavailable coverages
  if (type === "coverage") {
    result = Object.keys(all).reduce((acc, key) => {
      if (!all[key]["deductible"].includes(selected)) {
        const uniqueVals = getUniqueVals(key, "coverage", all)
        acc = [...acc, ...uniqueVals]
      }
      return acc
    }, [])
    if (selected === 2500 || selected === 5000 || selected === 10000) {
      result = result.concat([10000])
    }
  }
  if (type === "deductible") {
    result = Object.keys(all).reduce((acc, key) => {
      if (!all[key]["coverage"].includes(selected)) {
        const uniqueVals = getUniqueVals(key, "deductible", all)
        acc = [...acc, ...uniqueVals]
      }
      return acc
    }, [])

    if (selected === 10000) {
      result = result.concat([2500, 5000, 10000])
    }
  }
  return result
}

export const getCoverages = all => {
  return [
    ...new Set(
      Object.keys(all)
        .reduce((acc, key) => {
          acc = acc.concat(all[key]["coverage"])
          return acc
        }, [])
        .sort((a, b) => a - b)
    ),
  ]
}
export const getDeductibles = all => {
  return [
    ...new Set(
      Object.keys(all)
        .reduce((acc, key) => {
          acc = acc.concat(all[key]["deductible"])
          return acc
        }, [])
        .sort((a, b) => a - b)
    ),
  ]
}

export const validTravelPlan = travelPlan => {
  if (!travelPlan.travelFrom) return false
  if (!travelPlan.travelTo) return false
  if (!travelPlan.startDate) return false
  if (!travelPlan.endDate) return false
  return true
}

export const validTravellers = travellers => {
  const policyHolder = travellers.policyHolder
  if (!policyHolder) return false
  if (
    !policyHolder.firstName ||
    !policyHolder.lastName ||
    !policyHolder.dob ||
    !policyHolder.gender
  )
    return false
  const spouse = travellers.spouse
  if (Object.keys(spouse).length > 0) {
    if (!spouse.firstName || !spouse.lastName || !spouse.dob || !spouse.gender)
      return false
  }
  const dependents = travellers.dependents
  if (dependents.length > 0) {
    for (let dep of dependents) {
      if (!dep.firstName || !dep.lastName || !dep.dob || !dep.gender)
        return false
    }
  }
  return true
}

export const setTravelPlanPayload = (
  travelPlan,
  purpose = "not specified"
) => ({
  departing_date: travelPlan.startDate.format("YYYY-MM-DD"),
  returning_date: travelPlan.endDate.format("YYYY-MM-DD"),
  to: provinceCode[travelPlan.travelTo],
  from: travelPlan.travelFrom,
  purpose,
})

export const setPayloadForQuote = (
  travelPlanPayload,
  travellersPayload,
  coverage,
  deductible,
  options = []
) => {
  return {
    travel_plan: travelPlanPayload,
    travelers: travellersPayload,
    policy: {
      coverage,
      deductible,
    },
    options,
  }
}

// const mockPlan = {
//   insurer: "gms",
//   logo: "gms.fluid",
//   premium: 123.0,
//   coverageItems: ["Medical emergency", "Pre-existing conditions"],
//   triplength
// }

export const setPlanCardData = (
  insurer,
  logo,
  premium,
  coverageItems,
  triplength
) => ({
  insurer,
  logo,
  premium,
  coverageItems,
  triplength,
})

export const checkInsuerAvailability = (
  coverage,
  deductible,
  all,
  preExisting
) => {
  let result = Object.keys(all).reduce((acc, key) => {
    acc[key] =
      all[key]["coverage"].includes(coverage) &&
      all[key]["deductible"].includes(deductible)
    return acc
  }, {})

  if (!preExisting) {
    Object.keys(result).forEach(insurer => {
      result[insurer] = preExistingCoverage[insurer] !== null
    })
  }
  return result
}

export const ifAllQuotesLoaded = (quotes, availability) => {
  for (const insurer of Object.keys(quotes)) {
    if (availability[insurer] && Object.keys(quotes[insurer]).length <= 0)
      return false
  }
  return true
}

export const isCompareInsurers = compareInsurer => {
  for (const insurer of Object.keys(compareInsurer)) {
    if (compareInsurer[insurer]) return true
  }
  return false
}

export const getPaymentInfo = () => {
  return Object.keys(paymentInfoItems).reduce((acc, i) => {
    acc[i] = typeof window !== "undefined" && window.sessionStorage.getItem(i)
    return acc
  }, {})
}
export const getPayment = () => {
  return paymentItems.reduce((acc, i) => {
    acc[i] = typeof window !== "undefined" && window.sessionStorage.getItem(i)
    return acc
  }, {})
}

export const validPaymentInfo = () => {
  const paymentInfo = getPaymentInfo()
  if (
    !paymentInfo.email ||
    !paymentInfo.confirmEmail ||
    paymentInfo.email !== paymentInfo.confirmEmail ||
    !paymentInfo.address ||
    !paymentInfo.city ||
    !paymentInfo.province ||
    !paymentInfo.postalCode
  )
    return false
  return true
}

export const validPayment = () => {
  const payment = getPayment()
  if (
    !payment.cardHolderName ||
    !payment.cardNumber ||
    !payment.expireMonth ||
    !payment.expireYear ||
    !payment.cvc
  )
    return false
  return true
}

const gmsVisitorType = {
  notInCA: "OutsideOfCanada",
  inCA: {
    replace: "InCanadaReplacingACurrentPlan",
    lessThan30days: "InCanadaForLessThan30DaysAndNoCanadianPlan",
    moreThan30days: "InCanadaForMoreThan30DaysAndNoCanadianPlan",
  },
}

export const setPurchasePayload = (
  address,
  city,
  province,
  postalCode,
  email,
  phone,
  travelPlan,
  travellers,
  coverage,
  deductible,
  purchaseConfirmation,
  selectedPlan,
  nameOnCard,
  cardNumber,
  expireMonth,
  expireYear,
  cvc
) => {
  return {
    language: "en",
    address: {
      street_line_1: address,
      street_line_2: "",
      city,
      province: province.toUpperCase(),
      postal_code: postalCode,
    },
    email,
    phone_number: phone,
    application: {
      travel_plan: setTravelPlanPayload(travelPlan),
      travelers: setTravellersPayload(travellers),
      policy: {
        coverage,
        deductible,
      },
      visitor_details: {
        visitor_type:
          purchaseConfirmation.location &&
          !purchaseConfirmation.location.includes("not")
            ? purchaseConfirmation.situation.includes("replace")
              ? gmsVisitorType["inCA"]["replace"]
              : purchaseConfirmation.situation.includes("less")
              ? gmsVisitorType["inCA"]["lessThan30days"]
              : gmsVisitorType["inCA"]["moreThan30days"]
            : gmsVisitorType["notInCA"],
        existing_plan: {
          policy_number: purchaseConfirmation.currentPolicy.policyNumber,
          company_name: purchaseConfirmation.currentPolicy.company,
          expiry_date:
            purchaseConfirmation.currentPolicy.endDate &&
            purchaseConfirmation.currentPolicy.endDate.format("YYYY-MM-DD"),
        },
      },
      options: selectedPlan.coverageItems.map(item => ({ code: item })),
    },
    payment: {
      name_on_card: nameOnCard.trim(),
      number: cardNumber.trim(),
      expiry_month: expireMonth.trim(),
      expiry_year: "20" + expireYear.trim(),
      cvd: cvc.trim(),
    },
  }
}
