import { css } from "@emotion/core"

import step1 from "../../images/step1.svg"
import step2 from "../../images/step2.svg"
import step3 from "../../images/step3.svg"
import { mq } from "./media-query"

const imgStyle = {
  width: "50%",
  margin: "0 auto 1rem auto",
  textAlign: "center",
  [mq[0]]: {
    margin: "0 auto",
  },
  [mq[0]]: {
    margin: "0 auto -1rem auto",
  },
}
const extraImgStyle = {
  paddingTop: "1.5rem",
}

const textStyle = {
  fontFamily: "var(--ff-quaternary)",
  margin: "0.5rem auto",
  color: "var(--clr-primary-300)",
  fontSize: "1rem",
  width: "80%",
  paddingBottom: "1rem",
  [mq[0]]: {
    width: "100%",
  },
}

const cardStyle = css`
  background: none;
  /* padding: 3rem 1.5rem; */
  border-radius: var(--border-radius-md);
  text-align: center;
  height: 100%;
  border: 1px solid var(--clr-primary-300);
  box-shadow: 0.2rem 0.2rem 0 0 var(--clr-primary-300);
  margin: 0 1.5rem 3.5rem 1.5rem;
  ${mq[0]} {
    border: none;
    box-shadow: none;
    display: grid;
    grid-template-rows: 70% 30%;
  }
`

export default [
  {
    image: {
      src: step1,
      alt: "step1-image",
    },
    label: "step1",
    text: "Tell us your trip details. We don't need much.",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    textStyle,
    cardStyle,
  },
  {
    image: {
      src: step2,
      alt: "step2-image",
    },
    label: "step2",
    text: "Compare different plans and prices. Choose the one that suits you.",
    imgStyle: { ...imgStyle, ...extraImgStyle },
    textStyle,
    cardStyle,
  },
  {
    image: {
      src: step3,
      alt: "step3-image",
    },
    label: "step3",
    text: "Pay online and get your policy right away",
    imgStyle,
    textStyle,
    cardStyle,
  },
]
